<template>
	<div>
		<nav class="menu">
			<BaseButtonClose @click.native="close" />
			<slot />
		</nav>

		<BaseOverlay :click="toggle" />
	</div>
</template>

<script>
import {EventBus} from '../event-bus';


export default {
	name: 'AppMenu',
	data() {
		return {
			active: false
		};
	},
	created() {
		EventBus.$on('AppMenu:open', this.open);
		EventBus.$on('AppMenu:close', this.close);
		EventBus.$on('AppMenu:toggle', this.toggle);
		document.addEventListener('keydown', this.onKeypress);
	},
	beforeDestroy() {
		EventBus.$off('AppMenu:open');
		EventBus.$off('AppMenu:close');
		EventBus.$off('AppMenu:toggle');
		document.removeEventListener('keydown', this.onKeypress);
	},
	methods: {
		toggle() {
			if (this.active) {
				this.close();
			} else {
				this.open();
			}
		},
		open() {
			this.active = true;
			document.body.classList.add('show-menu');
			EventBus.$emit('AppMenu:onOpen');
		},
		close() {
			this.active = false;
			document.body.classList.remove('show-menu');
			EventBus.$emit('AppMenu:onClose');
		},
		onKeypress(e) {
			if (!this.active) {
				return;
			}

			// Esc
			if (e.keyCode == 27) {
				e.preventDefault();
				this.close();
			}
		}
	}
};
</script>

<style lang="scss">
	@import '../scss/components/menu';
</style>
